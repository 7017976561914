<template>
  <list-template :hasFooter="true">
    <template v-slot:header>
      <el-form ref="form" size="mini" inline>
          <slot name="condition"></slot>
        <!-- <el-row type="flex" :gutter="10">
        </el-row> -->
      </el-form>
      <el-form ref="lo" class="list-operation" size="mini" inline>
        <el-row type="flex" justify="space-between">
          <!-- <el-col> -->
          <slot name="normalOperation"></slot>
          <!-- </el-col>
          <el-col> -->
          <slot name="batchOperation"></slot>
          <!-- </el-col> -->
        </el-row>
      </el-form>
    </template>
    <!-- <template slot="main" slot-scope="mscope"> -->
    <template v-slot:main="mscope">
      <el-table ref="dataTable" border fit size="mini" style="width: 100%" :highlight-current-row="highlightCurrentRow" :stripe="stripe" :height="tableHeight || (mscope.clientHeight - 60)" :data="rows" :default-sort="defaultSort" :row-class-name="rowClassName" :cell-class-name="cellClassName" @row-dblclick="(row, column, event)=>$emit('row-dblclick',row, column, event)" @sort-change="handleSortChange" @current-change="handleSelectionChange" @selection-change="handleSelectionChange">
        <el-table-column v-if="batchColumn" type="selection" :width="39" :selectable="isDisabled"></el-table-column>
        <el-table-column v-if="indexColumn" type="index" width="50" :index="rowIndex" align="center">
        </el-table-column>
        <el-table-column v-if="multipleSelected" type="selection" width="50" align="center">
        </el-table-column>
        <slot name="columns"></slot>
        <template v-for="columnHeader in columns">
          <el-table-column v-if="columnHeader.customer" :key="columnHeader.prop" :prop="columnHeader.prop" :label="columnHeader.label" :min-width="columnHeader.minWidth || ''" :width="columnHeader.width || ''" :header-align="columnHeader.headerAlign || 'center'" :align="columnHeader.align || 'center'">
            <template #default="scope">
              <component v-for="(actionItem,index) in columnHeader.customer" :key="index" :data="scope" :is="actionItem"></component>
            </template>
          </el-table-column>
          <el-table-column v-else :key="columnHeader.prop" :sortable="columnHeader.sortable || false" :prop="columnHeader.prop" :formatter="columnHeader.formatter" :label="columnHeader.label" :min-width="columnHeader.minWidth || ''" :width="columnHeader.width || ''" :header-align="columnHeader.headerAlign || 'center'" :align="columnHeader.align || 'center'">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <template v-slot:footer type="flex" justify="space-between">
      <!-- <el-form class="list-operation" size="mini" inline>

      </el-form> -->
      <el-pagination v-show="footerShow" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange" v-model:current-page="pageInfo.pageIndex" :page-sizes="size" :page-size="pageInfo.pageSize" layout="total, sizes, prev, pager, next, jumper " :total="total">
      </el-pagination>
    </template>
    <slot></slot>
  </list-template>
</template>

<style lang="scss" scoped>
// @import "@/styles/list-layout.scss";
// .list-operation{
//   border-top: solid 1px #e6e6e6
// }
</style>

<script>
import { getSort } from '@/utils/PageHelper'
import ListTemplate from '@/components/templates/listTemplate'
import { mapGetters } from 'vuex'

export default {
  components: {
    ListTemplate
  },
  emits: ['page-info-change','selected','row-dblclick','update:listQuery'],
  /**
     * 输入参数
     */
  props: {
    /**
     * 标题
     */
    title: {
      type: String,
      default: '页面'
    },
    listQuery: {
      type: Object,
      default: function () {
        return {
          condition: {
          },
          pageInfo: {
            pageIndex: 1,
            pageSize: 20,
            orders: []
          },
          listConditionTemplateDialogTitle: ''
        }
      } },
    /**
       * 列
       */
    columns: {
      type: Array,
      default: function () {
        return []
      }
    },
    /**
       * 是否多选
       */
    multipleSelected: {
      type: Boolean,
      default: false
    },
    batchColumn: {
      type: Boolean,
      default: false
    },
    /**
       * 是否包含索引列
       */
    indexColumn: {
      type: Boolean,
      default: false
    },
    /**
     * 是否显示底部分页
     */
    footerShow: {
      type: Boolean,
      default: true
    },
    /**
       * 数据行
       */
    rows: {
      type: Array,
      default: function () {
        return []
      }
    },
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },
    stripe: {
      type: Boolean,
      default: true
    },
    /**
     * 数据总数
     */
    total: {
      type: Number,
      default: 0
    },
    /**
       * 默认分页配置
       */
    pageSize: {
      type: Object,
      default: function () {
        return {
          default: 5,
          size: [5, 10, 20, 30, 50]
        }
      }
    },
    /**
       * 行样式名称
       */
    rowClassName: {
      type: Function,
      default: null
    },

    cellClassName: {
      type: Function,
      default: null
    },
    /**
       * 记住选择，在multipleSelected为true时可用
       */
    remberSelection: {
      type: Boolean,
      default: false
    },
    /**
       * 数据行的键
       */
    dataKey: {
      type: String
    },
    /**
       * 表格高度
       */
    tableHeight: {
      type: Number,
      default: 0
    },
    /**
     * 默认排序
     */
    defaultSort: {
      type: Object
    }
  },
  watch: {
    listQuery: {
      handler (newListQuery, oldListQuery) {
        if (JSON.stringify(newListQuery.condition) !== this.condition) {
          this.condition = JSON.stringify(newListQuery.condition)
          newListQuery.pageInfo.pageIndex = 1
        }
        var listQuery1 = this.dealWithListQueryChange(newListQuery)
        this.$store.dispatch('updatePageDetail', listQuery1)
      },
      deep: true
    },
    /**
       * 监控数据变化
       * @param  {Array} newRows 新数据
       * @param  {Array} oldRows 原数据
       */
    rows (newRows, oldRows) {
      if (
        Array.isArray(newRows) &&
        newRows.length > 0 &&
        this.multipleSelected &&
        this.remberSelection
      ) {
        var selectedRows = this.remberRows[`page_${this.pageInfo.pageIndex}`]
        if (Array.isArray(selectedRows) && selectedRows.length > 0) {
          /**
           * 选中记住的行
           */
          for (var i = 0; i < newRows.length; i++) {
            var row = newRows[i]
            if (
              selectedRows.find(sr => sr[this.dataKey] === row[this.dataKey])
            ) {
              this.$refs.dataTable.toggleRowSelection(row)
            }
          }
          /**
             * 移除已不存在的行
             */
          this.remberRows[`page_${this.pageInfo.pageIndex}`] = selectedRows.filter(
            sr => {
              return newRows.find(nr => nr[this.dataKey] === sr[this.dataKey]) !== undefined
            }
          )
        }
      }
    },
    pageInfo:{
      handler: function(newV,oldV){
        //注意目的是在组件分页数据发生变化时，更新父级组件的listQuery
        this.onPageInfoChange()
      },
      deep: true
    }
  },
  data () {
    return {
      pageInfo: {
        pageIndex: 1,
        pageSize: 20,
        orders: []
      },
      size: [10, 20, 30, 50],
      selectedRows: [],
      remberRows: {},
      condition: JSON.stringify(this.listQuery.condition),
    }
  },
  computed: {
    ...mapGetters(['pageDetailList']),
  },
  methods: {
    /**
     * 处理listQuery
     */
    dealWithListQueryChange (newListQuery) {
      return { pagePath: this.$route.fullPath + '/' + this.listConditionTemplateDialogTitle, pageListQuery: JSON.parse(JSON.stringify(newListQuery)) }
    },
    /**
       * 清除多选
       */
    clearSelection () {
      this.$refs.dataTable.clearSelection()
    },
    /**
       * 行选中事件处理
       * @param {*} rows 行数据
       */
    handleSelectionChange (rows) {
      if (this.multipleSelected && this.remberRows) {
        this.remberRows[`page_${this.pageInfo.pageIndex}`] = rows
      }
      this.selectedRows = rows
      this.onSelected()
    },
    /**
       * 处理排序信息变化事件
       * @param {*} sort 排序信息
       */
    handleSortChange (sort) {
      this.pageInfo.orders = []
      if (sort.order) {
        this.pageInfo.orders.push(getSort(sort))
      }
      this.pageInfo.pageIndex = 1
      this.remberRows = {}
      // this.onPageInfoChange()
    },
    /**
       * 处理分页大小变化事件
       * @param {*} size 分页大小
       */
    handlePageSizeChange (size) {
      this.pageInfo.pageSize = size
      this.pageInfo.pageIndex = 1
      this.remberRows = {}
      // this.onPageInfoChange()
    },
    /**
       * 处理页码变化事件
       * @param {*} currentPage 页码
       */
    handlePageCurrentChange (currentPage) {
      // console.log('[ currentPage ] >', currentPage)
      // Object.assign(this.pageInfo, {pageIndex:currentPage})
      this.pageInfo.pageIndex = currentPage
      // this.onPageInfoChange()
    },
    /**
       * 外部条件变化时处理
       */
    handleConditionChange () {
      this.resetTable()
      // this.onPageInfoChange()
    },
    /**
       * 触发分页信息变化事件
       */
    onPageInfoChange () {
      // TODO:兼容老版本程序，后期可取消PageInfo的事件
      this.$emit('update:listQuery', {
        condition: this.listQuery.condition,
        pageInfo: this.pageInfo,
        listConditionTemplateDialogTitle: this.listQuery.listConditionTemplateDialogTitle
      })
      // console.log('pageInfo',this.pageInfo);;
      this.$emit('page-info-change', this.pageInfo)
    },
    /**
       * 触发数据选中事件
       */
    onSelected () {
      var rows
      if (!this.multipleSelected || !this.remberSelection) {
        rows = this.selectedRows
      } else {
        rows = {
          currentPageRows: this.selectedRows,
          allRows: this.remberRows
            .values()
            .reduce((all, value) => Array.from(new Set(all.concat(value))), [])
        }
      }
      this.$emit('selected', rows)
    },
    /**
       * 计算序号
       * @param {*} index 参数
       */
    rowIndex (index) {
      return this.pageInfo.pageSize * (this.pageInfo.pageIndex - 1) + index + 1
    },
    /**
       * 重置条件并触发条件变化事件
       */
    changeCondition () {
      this.handleConditionChange()
    },
    /**
       * 重置当前页
       */
    reloadCurrent () {
      this.onPageInfoChange()
    },
    /**
       * 重置表格排序、分页、内容
       */
    resetTable () {
      this.pageInfo.pageIndex = 1
      // this.pageInfo.orders = []
      // this.$refs.dataTable.clearSort()
      this.remberRows = {}
    },
    getPageDetail () {
      // var flag = false
      var pds = this.pageDetailList.filter(pd => pd.pagePath === this.$route.fullPath + '/' + this.listConditionTemplateDialogTitle)
      if (pds && pds.length > 0) {
        Object.assign(this.listQuery, JSON.parse(JSON.stringify( pds[0].pageListQuery)))
        this.condition = JSON.stringify(this.listQuery.condition)
      } else {
        this.$store.dispatch('addPageDetail', { pagePath: this.$route.fullPath + '/' + this.listConditionTemplateDialogTitle, pageListQuery: JSON.parse(JSON.stringify(this.listQuery)) })
      }
      Object.assign(this.pageInfo, this.listQuery.pageInfo)
      // this.pageInfo = this.listQuery.pageInfo
    },
    getListConditionTemplateDialogTitle () {
      this.listConditionTemplateDialogTitle = this.getHash(this.title)
    },
    getHash (input) {
      var I64BIT_TABLE =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-'.split('')
      var hash = 5381
      var i = input.length - 1

      if (typeof input === 'string') {
        for (; i > -1; i--) { hash += (hash << 5) + input.charCodeAt(i) }
      } else {
        for (; i > -1; i--) { hash += (hash << 5) + input[i] }
      }
      var value = hash & 0x7FFFFFFF

      var retValue = ''
      do {
        retValue += I64BIT_TABLE[value & 0x3F]
      }
      while (value >>= 6)

      return retValue
    },
    isDisabled(row,index){
      if(row.isDisabled){
        return false
      }else{
        return true
      }
    }
  },
  mounted () {
    if (this.defaultSort) {
      let order = getSort(this.defaultSort)
      if (!this.pageInfo.orders.find(i => i === order)) {
        this.pageInfo.orders.push(order)
      }
    }
    // this.pageInfo.pageSize = this.pageSize.default
    this.size = this.pageSize.size
    // this.onPageInfoChange()
  },
  created () {
    this.getListConditionTemplateDialogTitle()
   // this.getPageDetail()
  }
}
</script>

/**
 * 生成分页参数
 * @param {*} pageParam 分页参数
 */
export function toURLParams (pageParam) {
  var sorts = ''
  if (Array.isArray(pageParam.orders)) {
    sorts = pageParam.orders.reduce((memo, order) => {
      return `${memo}&sort=${order}`
    }, '')
  }
  return `page=${pageParam.pageIndex}&size=${pageParam.pageSize}${sorts}`
}

/**
 * 生成分页json参数
 * @param {*} pageParam 分页参数
 */
export function toJSONParam (pageParam) {
  return {
    pageIndex: pageParam.pageIndex,
    pageSize: pageParam.pageSize,
    orders: pageParam.orders
  }
}

/**
 * 获取排序信息
 * 参数结构 {prop:'name', order:'ascending'}
 * @param {*} param 信息
 */
export function getSort (param) {
  return `${param.prop},${param.order === 'ascending' ? 'asc' : 'desc'}`
}

<template>
  <el-container ref="container" >
    <!-- :style="{height: mainContainerHeight-20+'px'}" -->
    <el-scrollbar v-if="hasQuery" class="scroll-expand" style="margin-right: 15px;background:#fff;flex:none;">
      <el-aside width="300px">
        <slot name="aside"></slot>
      </el-aside>
    </el-scrollbar>
    <el-container style="background:#fff;padding:10px;">
      <el-header ref="filterHeader" height="auto">
        <slot name="header"></slot>
      </el-header>
      <el-main>
        <slot :clientHeight="clientHeight" name="main"></slot>
      </el-main>
      <el-footer ref="footer" height="30px" v-if="hasFooter">
        <el-row type="flex" justify="end" align="middle">
          <slot name="footer"></slot>
        </el-row>
        <slot></slot>
      </el-footer>
    </el-container>
  </el-container>
</template>

<style lang="scss">
@import "@/assets/styles/list-layout.scss";
</style>


<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'mainContainerHeight'
    ])
  },
  props: {
    hasQuery: Boolean,
    hasFooter: Boolean,
    // {
    //   type: Boolean,
    //   default: true
    // },
    height: Number
  },
  watch: {
    mainContainerHeight: function (newHeight, oldHeight) {
      this.resize()
    },
  },
  data () {
    return {
      clientHeight: null,
      temp: 0
    }
  },
  methods: {
    resize () {
      var that = this
      that.clientHeight = that.mainContainerHeight - this.$refs.filterHeader.$el.clientHeight
      that.clientHeight =  that.clientHeight - (this.hasFooter?(this.$refs.footer.$el.clientHeight):60)
      // if (this.topbar.expand) {
      //   that.clientHeight =  that.clientHeight + 60
      // }

      // if (this.hasFooter) {
      //   that.clientHeight = that.mainContainerHeight - this.$refs.filterHeader.$el.clientHeight - this.$refs.footer.$el.clientHeight - 0// 40来至与两个container 的 padding
      // } else {
      //   that.clientHeight = that.mainContainerHeight - this.$refs.filterHeader.$el.clientHeight - 60
      // }
    }
  },
  mounted () {
    var that = this
    this.$nextTick(function () {
      that.resize()
    })
  }
}
</script>
